<template>
  <div>
    <x-table
      ref="alarmTable"
      title="设备告警"
      :card="true"
      :options="options"
      @rowDetail="rowDetail"
      @rowSolve="rowSolve"
      @search="searchHandle">
      <template slot="alarmName" slot-scope="{ row }">
        <div class="badge badge-light-danger">
          {{ row.alarmName }}
        </div>
      </template>
      <template slot="alarmTime" slot-scope="scope">
        <div>{{ scope.row.alarmTime ? new Date(scope.row.alarmTime).cxFormat('yyyy-MM-dd hh:mm') : '/' }} </div>
      </template>
    </x-table>
    <b-modal
      id="modal-view"
      ref="myModal"
      size="lg"
      title-class="x-text-bold"
      body-class="pl-4 pr-4 x-scrollbar"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      scrollable
    >
      <alarm-detail v-model="alarmDetail" style="min-height: 55vh"></alarm-detail>
    </b-modal>
    <b-modal
      id="modal-solve"
      ref="solveModal"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="处理"
      cancel-title="取消"
      centered
      title="处理警告"
      @ok="solve"
      @hidden="hidden"
    >
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="处理结果" require>
          <b-form-textarea
            v-model="solveText"
            placeholder="请输入处理结果"
          />
        </x-form-feild>
      </x-form-validator>
    </b-modal>
  </div>
</template>
<script>
import { alarmLogSolve, getDeviceAlarmRecord } from '@/api/ops/alarm-record'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BFormTextarea, BModal,
} from 'bootstrap-vue'
import AlarmDetail from './AlarmDetail.vue'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    XTable,
    BFormTextarea,
    BModal,
    AlarmDetail,
  },
  data() {
    return {
      alarmDetail: {},
      solveModal: undefined,
      solveText: undefined,
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        cancelOnOutside: true,
        searchFold: true,
        lableVertical: true,
        // 新增anniu
        addBtn: false,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'detail', name: '详情', icon: 'EyeIcon', tip: '详情' },
          { action: 'solve', name: '处理', icon: 'FeatherIcon', tip: '处理' }],
        actionFilter: (action, row) => {
          if (row.state === 'solve') {
            return action === 'detail'
          }
          return action === 'detail' || action === 'solve'
        },
        columns: [{
          label: '设备ID',
          labelShow: true,
          prop: 'deviceId',
          editDisable: true,
          searchShow: true,
        }, {
          label: '设备名称',
          labelShow: true,
          prop: 'deviceName',
          linkUrl: '/device-center/device/view/{deviceId}',
          searchShow: false,
        }, {
          label: '所属产品',
          labelShow: true,
          prop: 'productName',
          linkUrl: '/device-center/products/view/{productId}',
          addShow: false,
          editShow: false,
          searchShow: false,
        }, {
          label: '告警名称',
          labelShow: true,
          prop: 'alarmName',
          rowShow: true,
          searchShow: false,
        }, {
          label: '处理状态',
          labelShow: true,
          type: 'select',
          prop: 'state',
          rowSelect: true,
          dictData: [{ state: '全部', id: undefined }, { state: '未处理', id: 'newer' }, { state: '已处理', id: 'solve' }],
          props: { label: 'state', value: 'id' },
          selectVariant: prop => {
            if (prop === 'solve') {
              return 'success'
            }
            return 'danger'
          },
          searchShow: true,
        }, {
          label: '告警时间',
          labelShow: true,
          sortable: true,
          prop: 'alarmTime',
          type: 'datetimerange',
          addShow: false,
          editShow: false,
          searchShow: true,
        },
        ],
      },
      productList: [],
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getDeviceAlarmRecord(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    solve(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.alarmLogSolve()
    },
    hidden() {
      this.solveText = undefined
    },
    alarmLogSolve() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          alarmLogSolve(this.solveId, this.solveText).then(() => {
            this.$nextTick(() => {
              this.solveText = undefined
              this.$refs.solveModal.hide()
              this.$refs.alarmTable.__searchHandle()
            })
            this.solveText = undefined
          })
        }
      })
    },
    rowDetail(data) {
      this.alarmDetail = data
      this.$refs.myModal.show()
    },
    rowSolve(data) {
      this.solveId = data.id
      this.$refs.solveModal.show()
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
